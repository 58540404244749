<template>
  <v-card>
    <v-row>
      <v-card-title class="ml-4">
        Importações por data
      </v-card-title>
      <v-spacer />
      <v-col
        cols="12"
        md="2"
        class="pb-1 mr-10"
      >
        <v-menu
          v-model="dataSelected"
          :close-on-content-click="false"
          :close-on-click="closeDatePicker"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterDate"
              clearable
              label="Selecione uma data"
              v-bind="attrs"
              v-on="on"
              @input="getDataIntegrationFilter"
            />
          </template>
          <v-date-picker
            v-model="filterDate"
            locale="pt-BR"
            width="270"
            @input="getDataIntegrationFilter"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-card-text v-if="dataConditional === true">
      <v-timeline
        v-for="integration in dataIntegration"
        :key="integration.id"
        dense
        class="timeline-custom-dense timeline-custom-dots"
      >
        <v-timeline-item
          small
          :color="integration.status === 'Concluído' ? 'success' : 'error'"
          class="mb-6"
        >
          <div class="d-flex align-start mb-3 flex-sm-row flex-column">
            <div>
              <!-- Header -->
              <div class="d-flex justify-space-between">
                <p class="mb-1 font-weight-semibold text-base text--primary">
                  {{ integration.name }}
                </p>
              </div>
              <span>{{ integration.status === 'Concluído' ? integration.historic : integration.historic.slice(40, 60) }}</span>
            </div>
          </div>
          <div class="mb-3">
            <p class="mb-1 text-base font-weight-semibold text--primary">
              Data importação
            </p>
            <span>{{ integration.date }}</span>
          </div>
          <div class="mb-3">
            <p class="mb-1 text-base font-weight-semibold text--primary">
              Cidade
            </p>
            <span>{{ integration.region }}</span>
          </div>
          <div class="mb-3">
            <p class="mb-1 text-base font-weight-semibold text--primary">
              Processamento
            </p>
            <span>{{ integration.processing }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <div class="">
              <p class="mb-1 text-base font-weight-semibold text--primary">
                Tipo arquivo
              </p>
              <span>{{ integration.file }}</span>
            </div>
          </div>
          <div class="mb-3">
            <p class="mb-1 text-base font-weight-semibold text--primary">
              Status
            </p>
            <v-alert
              text
              class="badge status"
              :color="integration.status === 'Concluído' ? 'success' : 'error'"
            >
              <span>{{ integration.status }}</span>
            </v-alert>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>

    <v-card-text v-else>
      <v-timeline
        v-for="integration in dataFilter"
        :key="integration.id"
        dense
        class="timeline-custom-dense timeline-custom-dots"
      >
        <v-timeline-item
          small
          :color="integration.status === 'Concluído' ? 'success' : 'error'"
          class="mb-6"
        >
          <div class="d-flex align-start mb-3 flex-sm-row flex-column">
            <div>
              <!-- Header -->
              <div class="d-flex justify-space-between">
                <p class="mb-1 font-weight-semibold text-base text--primary">
                  {{ integration.name }}
                </p>
              </div>
              <span>{{ integration.status === 'Concluído' ? integration.historic : integration.historic.slice(41, 299) }}</span>
            </div>
          </div>
          <div class="mb-3">
            <p class="mb-1 text-base font-weight-semibold text--primary">
              Data importação
            </p>
            <span>{{ integration.date }}</span>
          </div>
          <div class="mb-3">
            <p class="mb-1 text-base font-weight-semibold text--primary">
              Cidade
            </p>
            <span>{{ integration.region }}</span>
          </div>
          <div class="mb-3">
            <p class="mb-1 text-base font-weight-semibold text--primary">
              Processamento
            </p>
            <span>{{ integration.processing }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <div class="">
              <p class="mb-1 text-base font-weight-semibold text--primary">
                Tipo arquivo
              </p>
              <span>{{ integration.file }}</span>
            </div>
          </div>
          <div class="mb-3">
            <p class="mb-1 text-base font-weight-semibold text--primary">
              Status
            </p>
            <v-alert
              text
              class="badge status"
              :color="integration.status === 'Concluído' ? 'success' : 'error'"
            >
              <span>{{ integration.status }}</span>
            </v-alert>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'

export default {
  props: {
    dataIntegration: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataSelected: '',
      filterDate: '',
      dataFilter: [],
      dataConditional: true,
    }
  },

  created() {
    // this.getDataIntegrationFilter()
  },

  methods: {
    async closeDatePicker() {
      this.dataSelected = false
      await axiosIns.get('/api/v1/integration/queue_integration/index')
        .then(res => {
          this.dataFilter = res.data.data
        })
    },
    async getDataIntegrationFilter() {
      this.dataConditional = false
      this.dataSelected = false
      const body = {
        date: this.filterDate,
      }
      await axiosIns.post('/api/v1/integration/queue_integration/filter/', body)
        .then(res => {
          this.dataFilter = res.data.data
        })
    },
  },
}
</script>
<style>
.status{
  width: 110px;
}
</style>
