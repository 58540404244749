<template>
  <v-card>
    <v-card-title>
      Integração
    </v-card-title>

    <v-row>
      <v-card-text class="d-flex align-center flex-wrap mt-5 pb-0">
        <v-col
          cols="12"
          lg="4"
          md="6"
        >
          <v-select
            v-model="integrationType"
            dense
            :items="endpoints"
            :loading="loadingEndpoints"
            :disabled="controlEndpoints"
            outlined
            label="Tipo de integração"
          />
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="6"
        >
          <v-file-input
            v-model="files"
            outlined
            dense
            multiple
            label="Arquivos Integração"
          ></v-file-input>
        </v-col>

        <v-btn
          color="primary"
          class="mb-7 ml-3"
          :loading="loadingSend"
          @click="sendFiles()"
        >
          Enviar
        </v-btn>
      </v-card-text>
    </v-row>

    <timeline-integration
      :data-integration="dataItegration"
    />
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import TimelineIntegration from './TimelineIntegration.vue'

export default {
  components: {
    TimelineIntegration,
  },
  data: () => ({
    dataItegration: [],
    integrationType: null,
    files: [],
    endpoints: [],
    loadingSend: false,
    loadingEndpoints: true,
    controlEndpoints: true,
    rules: [v => !!v || 'Campo obrigatório'],
  }),

  created() {
    this.getEndpoints()
    this.getDataIntegration()
  },

  methods: {
    async getEndpoints() {
      this.endpoints = []
      await axiosIns.get('/api/v1/integration/integration_type/index').then(async res => {
        await res.data.data.map(dt => {
          this.endpoints.push({
            text: dt.type,
            value: dt.url,
          })
        })
        this.loadingEndpoints = false
        this.controlEndpoints = false
      })
    },

    async getDataIntegration() {
      await axiosIns.get('/api/v1/integration/queue_integration/index')
        .then(res => {
          this.dataItegration = res.data.data
        })
    },

    async sendFiles() {
      if (this.integrationType && this.files.length > 0) {
        this.loadingSend = true
        const formData = new FormData()
        this.files.forEach(file => {
          formData.append('files[]', file)
        })
        await axiosIns.post(this.integrationType, formData).then(res => {
          this.loadingSend = false
          this.$swal({
            icon: JSON.stringify(res.data.data[0]).includes('Conflito') ? 'error' : 'success',
            title: JSON.stringify(res.data.data[0]).includes('Conflito')
              ? 'Algo deu errado!'
              : 'Integração bem sucedida',
            text: `${JSON.stringify(res.data.data[0])}`,
            showConfirmButton: true,
          })
        })
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Espere um pouco...',
          text: 'Selecione um tipo de integração e um arquivo.',
          showConfirmButton: false,
          timer: 4000,
        })
      }
    },
  },
}
</script>
