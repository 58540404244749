import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Integração ")]),_c(VRow,[_c(VCardText,{staticClass:"d-flex align-center flex-wrap mt-5 pb-0"},[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"6"}},[_c(VSelect,{attrs:{"dense":"","items":_vm.endpoints,"loading":_vm.loadingEndpoints,"disabled":_vm.controlEndpoints,"outlined":"","label":"Tipo de integração"},model:{value:(_vm.integrationType),callback:function ($$v) {_vm.integrationType=$$v},expression:"integrationType"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"4","md":"6"}},[_c(VFileInput,{attrs:{"outlined":"","dense":"","multiple":"","label":"Arquivos Integração"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c(VBtn,{staticClass:"mb-7 ml-3",attrs:{"color":"primary","loading":_vm.loadingSend},on:{"click":function($event){return _vm.sendFiles()}}},[_vm._v(" Enviar ")])],1)],1),_c('timeline-integration',{attrs:{"data-integration":_vm.dataItegration}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }